import clsx from 'clsx'
import { msrpFormat } from 'shared-code/format'
import ArrowedButton from 'shared-components/buttons/ArrowedButton'
import stylesCommon from 'shared-components/styles/deals-widget.module.css'
import stylesDeals from 'shared-components/styles/deals.module.css'
import SplitterLine from 'shared-components/ui/SplitterLine'
import {
  BasicStyledComponent,
  DSDataAttrs,
  DSLabeledLink,
  DSNCDealMSRP,
  DSTextBadge,
} from 'shared-definitions/types'
import TextBadge from '../ui/TextBadge'
import styles from './DealsSimple.module.css'

interface DealsSimpleProps extends BasicStyledComponent {
  title: string
  moreLink: DSLabeledLink | null
  dataAttrs?: DSDataAttrs
  id?: string
  msrp?: DSNCDealMSRP | null
  textBadge: DSTextBadge | null
}

const DealsSimple: React.FC<DealsSimpleProps & React.PropsWithChildren> = ({
  children,
  className,
  moreLink,
  title,
  dataAttrs,
  id,
  msrp,
  textBadge,
}) => (
  <div id={id} {...dataAttrs} className={clsx(className, styles.container)}>
    {textBadge && <TextBadge {...textBadge} className={styles.textBadge} />}
    <div className={clsx(styles.widget, stylesDeals.bg, stylesDeals.border)}>
      <div className={clsx(styles.title, stylesCommon.titleFont)}>{title}</div>
      {children}
      <div>
        {msrp ? <span className={styles.msrp}>{msrpFormat(msrp)}</span> : null}
        {children ? <SplitterLine className={styles.separator} variant="bright" /> : null}
      </div>
      {moreLink ? (
        <ArrowedButton className={stylesCommon.refLinkDesktop} {...moreLink.pLink}>
          {moreLink.label}
        </ArrowedButton>
      ) : null}
    </div>
    {moreLink ? (
      <ArrowedButton className={stylesCommon.refLink} {...moreLink.pLink}>
        {moreLink.label}
      </ArrowedButton>
    ) : null}
  </div>
)

export default DealsSimple
