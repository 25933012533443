import clsx from 'clsx'
import { ComponentType } from 'react'
import styleUtils from 'shared-components/styles/util.module.css'
import {
  DSNCDisclosureBox,
  DSResource,
  DSTpl,
  NCComponentInterface,
} from 'shared-definitions/types'
import styles from './NCDisclosureBox.module.css'

interface NCDisclosureBoxProps<T extends DSResource> {
  data: DSNCDisclosureBox<T>
  tpl: DSTpl
  nc: ComponentType<NCComponentInterface<T>>
}

const NCDisclosureBox = <T extends DSResource>({
  data,
  tpl,
  nc: NC,
}: NCDisclosureBoxProps<T>): React.ReactElement => (
  <div
    className={clsx(styleUtils.nc, {
      [styles.accent]: data.variant === 'accent',
      [styles.all]: data.visible === 'all',
    })}
    data-container-type="content"
  >
    {data.blocks.map((item, index) => (
      <NC index={index} key={index} data={item} tpl={tpl} />
    ))}
  </div>
)

export default NCDisclosureBox
