import { ComponentType, CSSProperties, ReactElement, useState } from 'react'
import styleUtils from 'shared-components/styles/util.module.css'
import styles from './NCCollapsedBox.module.css'
import { DSNCCollapsedBox, DSResource, DSTpl, NCComponentInterface } from 'shared-definitions/types'
import { useScrollHeight } from '../hooks/use-scroll-height'
import clsx from 'clsx'
import RWButton from '../buttons/RWButton'

interface NCCollapsedBoxProps<T extends DSResource> {
  data: DSNCCollapsedBox<T>
  nc: ComponentType<NCComponentInterface<T>>
  tpl: DSTpl
}

const NCCollapsedBox = <T extends DSResource>({
  data,
  tpl,
  nc: NC,
}: NCCollapsedBoxProps<T>): ReactElement => {
  const { ref, height } = useScrollHeight<HTMLDivElement>()
  const [opened, setOpened] = useState(false)

  return (
    <div className={styleUtils.nc} data-container-type="content">
      <div
        className={clsx(styles.content, {
          [styles.opened]: opened,
        })}
        ref={ref}
        style={{ '--scroll-height': `${height}px` } as CSSProperties}
      >
        <div className={styles.collapsible}>
          {data.blocks.map((item, index) => (
            <NC index={index} key={index} data={item} tpl={tpl} />
          ))}
        </div>
      </div>
      <RWButton
        variant="tertiary"
        size="medium"
        className={styles.toggle}
        onClick={() => setOpened(s => !s)}
      >
        {opened ? data.labels.collapse : data.labels.expand}
        <svg
          className={clsx(styles.buttonIcon, {
            [styles.buttonIconOpen]: opened,
          })}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 21 21"
          fill="none"
        >
          <path
            d="M2.81242 13.4715C2.60375 13.4298 2.3935 13.324 2.26617 13.134C2.01134 12.7531 2.11809 12.219 2.50025 11.9648L9.9925 6.97818C10.2718 6.79235 10.6497 6.79235 10.9291 6.97818L18.4213 11.9648C18.8034 12.219 18.9102 12.7531 18.6554 13.134C18.4007 13.5148 17.8669 13.6215 17.4847 13.3673L10.4607 8.69234L3.43684 13.3673C3.24575 13.4948 3.02117 13.5131 2.81242 13.4715Z"
            fill="black"
          />
        </svg>
      </RWButton>
    </div>
  )
}

export default NCCollapsedBox
