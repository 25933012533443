import { FC } from 'react'
import styles from './NCSeparator.module.css'
import { DSNCSeparator } from 'shared-definitions/types'
import styleUtils from 'shared-components/styles/util.module.css'
import clsx from 'clsx'

interface NCSeparatorProps {
  data: DSNCSeparator
}

const NCSeparator: FC<NCSeparatorProps> = () => (
  <hr className={clsx(styleUtils.nc, styles.container)} />
)

export default NCSeparator
