import React from 'react'
import { DSNCBestListSection } from 'shared-definitions/types'
import styles from './NCBestListSection.module.css'
import AppImage from '../ui/AppImage'
import Linked from '../service/Linked'
import { htmlReactParse } from 'shared-code/html'

interface NCBestListSectionProps {
  data: DSNCBestListSection
}

const NCBestListSection: React.FC<NCBestListSectionProps> = ({ data }) => (
  <div className={styles.container}>
    {data.image && (
      <AppImage
        imageClassName={styles.imageImage}
        className={styles.image}
        image={data.image}
        sizes={80}
        layout="responsive"
      />
    )}
    <div className={styles.description}>
      <div className={styles.title}>{htmlReactParse(data.title)}</div>
      <Linked {...data.button.pLink} className={styles.button}>
        {data.button.label}
      </Linked>
    </div>
  </div>
)

export default NCBestListSection
