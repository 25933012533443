import clsx from 'clsx'
import { CSSProperties } from 'react'
import { htmlReactParse } from 'shared-code/html'
import styleUtils from 'shared-components/styles/util.module.css'
import { DSNCTrustUs } from 'shared-definitions/types'
import Linked from '../service/Linked'
import AppImage from '../ui/AppImage'
import styles from './NCTrustUs.module.css'
import { ThemeContext } from '../contexts/ThemeContext'

interface NCTrustUsProps {
  data: DSNCTrustUs
}

const NCTrustUs: React.FC<NCTrustUsProps> = ({ data }) => {
  const { ready, theme } = ThemeContext.useContainer()

  return (
    <div className={clsx(styles.container, styleUtils.nc)} data-container-type="content">
      <div
        className={styles.top}
        style={
          {
            '--iw': data.logoImage?.size?.w ?? 16,
            '--ih': data.logoImage?.size?.h ?? 9,
          } as CSSProperties
        }
      >
        {data.logoImage && (
          <AppImage
            imageClassName={clsx(styles.logoImage, {
              [styles.inverted]: ready && theme === 'dark',
            })}
            layout="raw"
            image={data.logoImage}
            sizes={320}
            className={styles.logo}
          />
        )}
        <h2 className={styles.title}>{data.title}</h2>
      </div>
      <div className={styles.achievements}>
        {data.achievements.map((achievement, index) => (
          <div key={index} className={styles.achievement}>
            <div className={styles.achievementTitle}>{achievement.title}</div>
            <div className={styles.metric}>{achievement.metric}</div>
          </div>
        ))}
      </div>
      <Linked {...data.pLink} className={styles.desc}>
        <div className={styles.text}>{htmlReactParse(data.description.textHtml)}</div>
        {data.description.image && (
          <AppImage
            layout="raw"
            imageClassName={styles.image}
            className={styles.textImage}
            image={data.description.image}
            sizes={140}
          />
        )}
      </Linked>
    </div>
  )
}

export default NCTrustUs
