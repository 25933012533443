/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx'
import { FC, useState } from 'react'
import { htmlReactParse } from 'shared-code/html'
import styleUtils from 'shared-components/styles/util.module.css'
import { DSNCAboutExpert } from 'shared-definitions/types'
import RWButton from '../buttons/RWButton'
import SocialIcon from '../service/SocialIcon'
import AppImage from '../ui/AppImage'
import styles from './NCAboutExpert.module.css'

interface NCAboutExpertProps {
  data: DSNCAboutExpert
}

const NCAboutExpert: FC<NCAboutExpertProps> = ({ data }) => {
  const [collapsed, setCollapsed] = useState(true)

  return (
    <div className={clsx(styles.container, styleUtils.nc)}>
      {data.label && <div className={styles.label}>{data.label}</div>}
      {data.teamMeber.image && (
        <AppImage layout="raw" image={data.teamMeber.image} className={styles.image} sizes={48} />
      )}
      <div className={styles.name}>{data.teamMeber.name}</div>
      {data.teamMeber.description && (
        <div
          onClick={() => setCollapsed(false)}
          role={!collapsed ? undefined : 'button'}
          tabIndex={!collapsed ? undefined : 0}
          className={clsx(styles.description, {
            [styles.descriptionCollapsed]: collapsed,
          })}
        >
          {htmlReactParse(data.teamMeber.description)}
        </div>
      )}
      <div className={styles.bottomLine}>
        {data.teamMeber.socials.map((icon, iconIndex) => (
          <RWButton key={iconIndex} pLink={icon.pLink} variant="tertiary" size="small">
            <SocialIcon className={styles.socialIcon} icon={icon.icon} />
          </RWButton>
        ))}
        <RWButton pLink={data.teamMeber.pLink} variant="tertiary" size="small">
          {data.profileLabel}
        </RWButton>
      </div>
    </div>
  )
}

export default NCAboutExpert
